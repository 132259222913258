var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "tools-card",
      attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
    },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("h2", { staticClass: "starling-h2" }, [
          _vm._v(_vm._s(_vm.$t("app.tools.title")))
        ])
      ]),
      _vm._l(_vm.filteredTools, function(tool) {
        return _c(
          "v-flex",
          { key: tool.id + "_" + _vm.key, attrs: { xs12: "" } },
          [
            _c("v-hover", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return _c(
                        "v-card",
                        {
                          staticClass: "tool-card cursor-pointer radius-15",
                          class: "elevation-" + (hover ? 12 : 3),
                          on: {
                            click: function($event) {
                              return _vm.goTo(tool)
                            }
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-2" },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    row: "",
                                    "justify-center": "",
                                    "align-center": "",
                                    "gap-xs-3": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      tool.icon
                                        ? _c(
                                            "v-responsive",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cover: "" }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "primary--text"
                                                },
                                                [_vm._v(_vm._s(tool.icon))]
                                              )
                                            ],
                                            1
                                          )
                                        : tool.iconComponent &&
                                          _vm.loadedProps[tool.id]
                                        ? _c(
                                            tool.iconComponent.name,
                                            _vm._b(
                                              {
                                                tag: "component",
                                                style:
                                                  "cursor: pointer;!important"
                                              },
                                              "component",
                                              _vm.loadedProps[tool.id],
                                              false
                                            )
                                          )
                                        : _c(
                                            "v-responsive",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cover: "" }
                                            },
                                            [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  size: 100,
                                                  color: "primary",
                                                  indeterminate: ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  ),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "starling-body StarlingDarkblue--text"
                                      },
                                      [_vm._v(_vm._s(tool.subtitle))]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-card-actions", { staticClass: "pa-2" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "starling-body StarlingPrimary1--text"
                              },
                              [_vm._v(_vm._s(tool.title))]
                            )
                          ])
                        ],
                        1
                      )
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }